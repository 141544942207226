import { Injectable } from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { environment } from 'src/environments/environment'; 
import { HttpClient } from '@angular/common/http';
import { Platform } from '@ionic/angular'; 
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  is_btn_disable = false;
  token:any = '';
  lang:any = '';
  constructor(private storage: Storage,private http: HTTP,public httpClient: HttpClient,private platform: Platform) {
    this.storage.get('token').then(token => {
      this.token = token;
    });
    this.storage.get('lang').then(lang => {
      this.lang = lang;
    });
  }

  async get(url: string, data: any): Promise<any> {
    this.is_btn_disable = true;
    data.token = this.token;
    data.applang = this.lang;
    if(this.platform.is('android') || this.platform.is('ios')){
      this.is_btn_disable = false;
      return this.http.get(environment.API_URL+url, data, {});
    }else{
      this.is_btn_disable = false;
      return this.httpClient.get(environment.API_URL+url, data).toPromise();
    }
  }

  async post(url: string, data: any): Promise<any> {
    this.is_btn_disable = true;
    data.token = this.token;
    data.applang = this.lang;
    if(this.platform.is('android') || this.platform.is('ios')){
      this.is_btn_disable = false;
      return this.http.post(environment.API_URL+url, data, {});   
    }else{
      this.is_btn_disable = false;
      return this.httpClient.post(environment.API_URL+url, data).toPromise();
    }
  }
  
}

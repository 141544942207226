import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  }, 
  {
    path: 'login',
    loadChildren: () => import('./views/login/login.module').then( m => m.LoginPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: false,
      role: 'login',
      isHidemenuList: false,
    }
  },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then( m => m.HomePageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'home',
      isHidemenuList: false,
    }
  },
  {
    path: 'category',
    loadChildren: () => import('./views/category/category.module').then( m => m.CategoryPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'category',
      isHidemenuList: false,
    }
  },
  {
    path: 'product',
    loadChildren: () => import('./views/product/product.module').then( m => m.ProductPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'product',
      isHidemenuList: false,
    }
  },
  {
    path: 'stock',
    loadChildren: () => import('./views/stock/stock.module').then( m => m.StockPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'stock',
      isHidemenuList: false,
    }
  },
  {
    path: 'customer',
    loadChildren: () => import('./views/customer/customer.module').then( m => m.CustomerPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'customer',
      isHidemenuList: false,
    }
  },
  {
    path: 'invoice',
    loadChildren: () => import('./views/invoice/invoice.module').then( m => m.InvoicePageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'invoice',
      isHidemenuList: false,
    }
  },
  {
    path: 'invoice-list',
    loadChildren: () => import('./views/invoice-list/invoice-list.module').then( m => m.InvoiceListPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'invoice',
      isHidemenuList: false,
    }
  },
  {
    path: 'invoice-updateview',
    loadChildren: () => import('./views/invoice-list/invoice-updateview/invoice-updateview.module').then( m => m.InvoiceUpdateviewPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'invoice',
      isHidemenuList: false,
    }
  },
  {
    path: 'user',
    loadChildren: () => import('./views/user/user/user.module').then( m => m.UserPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'user',
      isHidemenuList: false,
    }
  },
  {
    path: 'user-add',
    loadChildren: () => import('./views/user/user-add/user-add.module').then( m => m.UserAddPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'user',
      isHidemenuList: false,
    }
  },
  {
    path: 'user-update',
    loadChildren: () => import('./views/user/user-update/user-update.module').then( m => m.UserUpdatePageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'user',
      isHidemenuList: false,
    }
  },
  {
    path: 'supplier',
    loadChildren: () => import('./views/supplier/supplier.module').then( m => m.SupplierPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'supplier',
      isHidemenuList: false,
    }
  },
  {
    path: 'report',
    loadChildren: () => import('./views/report/report.module').then( m => m.ReportPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'report',
      isHidemenuList: false,
    }
  },
  {
    path: 'setting',
    loadChildren: () => import('./views/setting/setting.module').then( m => m.SettingPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'setting',
      isHidemenuList: false,
    }
  },
  {
    path: 'branch',
    loadChildren: () => import('./views/branch/branch.module').then( m => m.BranchPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'branch',
      isHidemenuList: false,
    }
  },
  {
    path: 'payment',
    loadChildren: () => import('./views/payment/payment.module').then( m => m.PaymentPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'payment',
      isHidemenuList: false,
    }
  },
  {
    path: 'renting',
    loadChildren: () => import('./views/renting/renting.module').then( m => m.RentingPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'renting',
      isHidemenuList: false,
    }
  },
  {
    path: 'activity',
    loadChildren: () => import('./views/activity/activity.module').then( m => m.ActivityPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'activity',
      isHidemenuList: false,
    }
  },
  {
    path: 'expenses',
    loadChildren: () => import('./views/expenses/expenses.module').then( m => m.ExpensesPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'expenses',
      isHidemenuList: false,
    }
  },
  {
    path: 'appointment',
    loadChildren: () => import('./views/appointment/appointment.module').then( m => m.AppointmentPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'appointment',
      isHidemenuList: false,
    }
  },
  {
    path: 'account',
    loadChildren: () => import('./views/account/account.module').then( m => m.AccountPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'account',
      isHidemenuList: false,
    }
  },
  {
    path: 'massage',
    loadChildren: () => import('./views/massage/massage.module').then( m => m.MassagePageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'massage',
      isHidemenuList: false,
    }
  },
  {
    path: 'profit',
    loadChildren: () => import('./views/profit/profit.module').then( m => m.ProfitPageModule),
    canActivate: [AuthGuardService],
    data: { 
      login: true,
      role: 'profit',
      isHidemenuList: false,
    }
  },  {
    path: 'documentation',
    loadChildren: () => import('./views/documentation/documentation.module').then( m => m.DocumentationPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./views/support/support.module').then( m => m.SupportPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

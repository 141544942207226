import { Injectable } from '@angular/core';
import { Router,CanActivate,ActivatedRouteSnapshot } from '@angular/router';
import { AppServiceService } from './app-service.service';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{
  constructor(private storage: Storage,private router: Router,private appService: AppServiceService) { }
  canActivate(route: ActivatedRouteSnapshot): any {
    const role = route.data.role;
    const login = route.data.login;
    const isHidemenuList = route.data.isHidemenuList;
    if(login){
      this.appService.ishideMenu = false;
      return this.storage.get('token').then(token => {
        if(token == null){
          this.router.navigate(["login"]);
          return false;
        }else if(token == ''){
          this.router.navigate(["login"]);
          return false;
        }else{
          return this.storage.get('permission').then(permission => {
            if(permission[role]){
              if(isHidemenuList){
                this.appService.ishideMenu = true;
              }
              return true;
            }else{
              this.router.navigate(["login"]);
              return false;
            }
          });
        }
      });
    }else{
      this.appService.ishideMenu = true;
      return this.storage.get('token').then(token => {
        if(token == null){
          return true;
        }else if(token == ''){
          return true;
        }else{
          window.location.replace("/home");
          return false;
        }
      });
    }
  }
}

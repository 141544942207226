import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AppServiceService } from 'src/app/services/app-service.service';
import { RequestsService } from 'src/app/api/requests.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  
  
  public appPages = [];
  public branch = null;
  public lang: any;
  public  set_select_child = (arr,index) => {
    if(typeof arr.find(x => x.url === window.location.pathname) != 'undefined'){
      if(typeof this.appPages[index].show == 'undefined'){
        setTimeout(() => {
          this.appPages[index].show = true;
        }, 500);
      }
    }
  }

  public  get_status_select = (url,index = null) => {
    if (url == window.location.pathname){
      return true;
    }else{
      return false;
    } 
  }

  constructor(
    public appService: AppServiceService,
    public menuCtrl: MenuController,
    private storage: Storage,
    private router: Router,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private apiService: RequestsService,
  ) {
 
  }
  public textColor: any;

  changeColor() {
    this.textColor = 'red';
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.storage.get('lang').then(lang => {
        if (lang == null) {
          this.translate.setDefaultLang('en');
          this.storage.set('lang', 'en');
          setTimeout(() => {
            this.lang = 'en';
          }, 1000);
        } else {
          this.translate.setDefaultLang(lang);
          setTimeout(() => {
            this.lang = lang;
          }, 1000);
        }
      });
      this.storage.get('menu_arr').then(menu_arr => {
        this.appPages = JSON.parse(menu_arr);
      });
    });
  }

  ngOnInit() {
    this.appService.user_list().then((response:any) =>{
      if (response.permission.branch) {
        this.appService.branch_list();
      }
      this.storage.get('branch').then((data) => {
        if(data){
          this.branch = "" + data;
        }else{
          this.branch = ""+response.branch_id_fk;
        }
      });
    });
    this.initializeApp();
  }

  logout() {
    this.storage.set('token', null).then(token => {
      this.storage.set('permission', null).then(permission => {
        this.storage.set('branch', null).then(permission => {
          this.router.navigate(["login"]);
        });
      });
    });
  }

  setLang(event) {
    this.apiService.lang = this.lang;
    this.translate.setDefaultLang(this.lang);
    this.storage.set('lang', this.lang);
  }

  setbranch(branch) {
    this.storage.set('branch', branch);
  }


}